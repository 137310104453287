import React, { useEffect } from 'react';
import Page from 'src/components/Layout/Page';
import { Container, Button, Theme, Box, useTheme } from '@mui/material';
import Lottie from 'react-lottie';
import confetti from 'src/assets/lottie/confetti.json';
import Emoji from 'components/Utils/Emoji';
import { mentoringApplyResultPageLogger } from 'utils/loggers';
import { motion } from 'framer-motion';

const SandboxApplyResultPage = () => {
  const { spacing } = useTheme<Theme>();

  useEffect(() => {
    mentoringApplyResultPageLogger.view();
  }, []);

  return (
    <Page title="루비콘 샌드박스 신청 완료">
      <Container>
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            padding: `0 ${spacing(1)}`,
            '& > div': {
              width: {
                md: 'auto',
                lg: '50% !important',
              },
              height: {
                md: 'auto',
                lg: '50% !important',
              },
            },
          }}
        >
          <Lottie
            options={{
              animationData: confetti,
              loop: false,
              autoplay: true,
            }}
          />
        </Box>
        <Box sx={{ margin: '30vh 0', textAlign: 'center' }}>
          <motion.h1 initial={{ opacity: 0, y: 30 }} animate={{ opacity: 1, y: 0 }} transition={{ delay: 0.5 }}>
            루비콘 샌드박스에 지원해주셔서 감사합니다!
          </motion.h1>
          <motion.p initial={{ opacity: 0, y: 30 }} animate={{ opacity: 1, y: 0 }} transition={{ delay: 1 }}>
            지원해주신 신청서를 검토 후 2~3주 안에 적어주신 이메일로 연락드릴게요! <Emoji emoji="🙇‍♂️" name="thanks" />
          </motion.p>
          <Box
            component={motion.div}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1.2 }}
            sx={{ marginTop: 5 }}
          >
            <Button color="primary" size="large" href="/" variant="contained" disableElevation>
              홈으로
            </Button>
          </Box>
        </Box>
      </Container>
    </Page>
  );
};

export default SandboxApplyResultPage;
